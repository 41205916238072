/* animate page transitions */
section.au-enter-active {
    -webkit-animation: fadeInRight 1s;
    animation: fadeInRight 1s;
}

div.au-stagger {
    /* 50ms will be applied between each successive enter operation */
    -webkit-animation-delay: 50ms;
    animation-delay: 50ms;
}

.card-container.au-enter {
    opacity: 0;
}

.card-container.au-enter-active {
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

.card {
    overflow: hidden;
    position: relative;
    border: 1px solid #CCC;
    border-radius: 8px;
    text-align: center;
    padding: 0;
    background-color: #337ab7;
    color: rgb(136, 172, 217);
    margin-bottom: 32px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.card .content {
    margin-top: 10px;
}

.card .content .name {
    color: white;
    text-shadow: 0 0 6px rgba(0, 0, 0, .5);
    font-size: 18px;
}

.card .header-bg {
    /* This stretches the canvas across the entire hero unit */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px #FFF solid;
    border-radius: 6px 6px 0 0;
}

.card .avatar {
    position: relative;
    margin-top: 15px;
    z-index: 100;
}

.card .avatar img {
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px #FFF solid;
}

ux-dialog-overlay.active {
    background-color: black;
    opacity: .5 !important;
}

/* animation definitions */
@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        -ms-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
